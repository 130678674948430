import { Injectable } from "@angular/core"

import { Observable, combineLatest, of, firstValueFrom } from "rxjs"
import { map, mergeMap, catchError } from "rxjs/operators"

import { AngularFireDatabase } from "@angular/fire/compat/database"

import { AuthService } from "./auth.service"
import { AccountService, District } from "./account.service"
import { Alert } from "./global.service"

import { upcomingDateDisplay, sortDateDisplay } from "../utils/date.utils"
import { prepareObjectForFirebaseUpload } from "../utils/collections.utils"

@Injectable()
export class AdminService {
    public adminDistrict: string

    constructor (private authService: AuthService, private db: AngularFireDatabase, private accountService: AccountService) {}

    public notReadOnly (): Observable<boolean> {
        return this.db.object<boolean>(`Users/${this.authService.uid.getValue()}/AdminReadOnly`).valueChanges().pipe(map(val => !val))
    }

    public getDistrictAccounts (): Observable<any[]> {
        // get the district string for the logged in admin user
        return this.authService.getAdminDistrict().pipe(mergeMap(district => {
            // save the string to avoid unnecessary async calls later on
            this.adminDistrict = district

            // get a list of registered accounts for the district
            return this.db.object(`Districts/${district}/Registered`).valueChanges().pipe(mergeMap((users: {[key: string]: string}) =>
                // return an array of profile observables with the account details mapped into them, filtering out any aggregate accounts
                combineLatest(Object.keys(users ?? {}).map(id =>
                    this.db.object(`Users/${users[id]}/Profile`).valueChanges().pipe(
                        catchError(error => {
                            if (error.code === "PERMISSION_DENIED") {
                                return of({})
                            } else {
                                throw error
                            }
                        }),
                        map(profile => {
                            profile = profile || {}
                            return {
                                id,
                                userID: users[id],
                                business: profile["BusinessName"],
                                contactName: profile["ContactName"],
                                contactPhone: profile["ContactPhone"],
                                email: profile["Email"],
                                name: undefined,
                                linked: undefined
                            }
                        }),
                        mergeMap(account =>
                            this.db.object(`Customers/${district}/${account.id}/Name`).valueChanges().pipe(map(name => {
                                account.name = name
                                return account
                            }))
                        ),
                        mergeMap(account =>
                            this.db.object(`Users/${this.accountService.uid}/Accounts/${district}/${account.id}`).valueChanges().pipe(map(linked => {
                                account.linked = linked
                                return account
                            }))
                        ),
                        map(account => {
                            // Don't show account ID or Remove button for combined accounts
                            if (isNaN(account.id as any)) {
                                account.id = ""
                                account["showRemove"] = false
                            } else {
                                account["showRemove"] = true
                            }
                            
                            return account
                        })
                    )
                ))
            ))
        }))
    }

    public getDistrictData (): Observable<{dashboardCards: any[], config: any}> {
        // get the information for the dashboard quarter cards
        return this.authService.getAdminDistrict().pipe(
            mergeMap(district =>
                combineLatest([
                    this.db.object(`Districts/${district}/Layout/Dashboard/QuarterCard1`).valueChanges(),
                    this.db.object(`Districts/${district}/Layout/Dashboard/QuarterCard2`).valueChanges(),
                    this.db.object(`Districts/${district}/Layout/Admin`).valueChanges()
                ]).pipe(map(items => {
                    const config = items.pop()
                    const dashboardCards = items.filter(Boolean).map((list: any[]) => {
                        // null check
                        if (!list["Data"]) {
                            list["Data"] = []
                        }

                        // save the index of each item as a property so we can reorder the list while still being able to delete the correct one
                        // if the item has a date, add a display date property so we can show the simplified version while retaining the full version for editing
                        list["Data"] = Object.keys(list["Data"]).map(key => {
                            const item = list["Data"][key]
                            item.index = key

                            if (item.Date) {
                                item.displayDate = upcomingDateDisplay(item.Date)
                            }

                            return item
                        }).sort((a, b) => sortDateDisplay(a.Date, b.Date)) // sort by date now that the original index is saved (sort function does nothing if there is no Date property)

                        return list
                    })
                    return {dashboardCards, config}
                }))
            )
        )
    }

    public async removeAccount (accountID: string, userID: string, linkCount: number): Promise<Error> {
        const promises: Promise<void>[] = []

        // Handle removing combined accounts when removing the second to last account for a district
        if (
            await firstValueFrom(this.db.object(`Users/${userID}/Accounts/${this.adminDistrict}/${userID}`).valueChanges()) &&
            linkCount <= 3 // Combined account, account being deleted, last one
        ) {
            promises.concat([
                this.db.object(`Users/${userID}/Accounts/${this.adminDistrict}/${userID}`).remove(),
                this.db.object(`Districts/${this.adminDistrict}/Registered/${userID}`).remove()
            ])
        }

        promises.concat([
            this.db.object(`Users/${userID}/Accounts/${this.adminDistrict}/${accountID}`).remove(),
            this.db.object(`Districts/${this.adminDistrict}/Registered/${accountID}`).remove()
        ])

        return Promise.all(promises).then(() => null).catch(error => error)
    }

    public addItem (type: string, data: any): Promise<Error> {
        // find the appropriate list, add the item to it, then return null if it succeeded
        return firstValueFrom(this.db.object(`Districts/${this.adminDistrict}/Layout/Dashboard`).valueChanges().pipe(mergeMap(dashboard => {
            const cardName = Object.keys(dashboard).find(card => dashboard[card].Type === type)

            // have to push then set to return the result as a Promise rather than PromiseLike (which lacks a catch property)
            return this.db.list(`Districts/${this.adminDistrict}/Layout/Dashboard/${cardName}/Data`).push({}).set(prepareObjectForFirebaseUpload(data)).then(() => null).catch(error => error)
        })))
    }

    public modifyItem (type: string, data: any, index: string): Promise<Error> {
        // find the appropriate list, push the updated data to the matching index, then return null if it succeeds
        return firstValueFrom(this.db.object(`Districts/${this.adminDistrict}/Layout/Dashboard`).valueChanges().pipe(mergeMap(dashboard => {
            const cardName = Object.keys(dashboard).find(card => dashboard[card].Type === type)

            return this.db.object(`Districts/${this.adminDistrict}/Layout/Dashboard/${cardName}/Data/${index}`).set(prepareObjectForFirebaseUpload(data)).then(() => null).catch(error => error)
        })))
    }

    public removeItem (type: string, index: string): Observable<Error> {
        // find the appropriate list, remove the object at the matching index, then return null if it succeeds
        return this.db.object(`Districts/${this.adminDistrict}/Layout/Dashboard`).valueChanges().pipe(mergeMap(dashboard => {
            const cardName = Object.keys(dashboard).find(card => dashboard[card].Type === type)

            return this.db.object(`Districts/${this.adminDistrict}/Layout/Dashboard/${cardName}/Data/${index}`).remove().then(() => null).catch(error => error)
        }))
    }

    public linkAdminAccount (id: string): PromiseLike<any> {
        return this.db.object(`Users/${this.accountService.uid}/Accounts/${this.adminDistrict}/${id}`).set(true).then(() => null).catch(error => error)
    }
    public unlinkAdminAccount (id: string): Promise<any> {
        return this.db.object(`Users/${this.accountService.uid}/Accounts/${this.adminDistrict}/${id}`).remove().then(() => null).catch(error => error)
    }

    public getAdminDistrictConfig (): Observable<District["config"]> {
        return this.authService.getAdminDistrict().pipe(mergeMap(district => this.db.object<District["config"]>(`System/DistrictSettings/${district}/Config`).valueChanges()))
    }
    public getLayoutConfig (): Promise<any> {
        return firstValueFrom(this.authService.getAdminDistrict().pipe(mergeMap(district => this.db.object(`Districts/${district}/Layout`).valueChanges())))
    }
    public async saveLayoutConfig (config: any): Promise<void> {
        const district = await firstValueFrom(this.authService.getAdminDistrict())
        return this.db.object(`Districts/${district}/Layout`).set(prepareObjectForFirebaseUpload(config, "YYYY-MM-DD"))
    }

    public saveAlert (alert: Alert): Promise<void> {
        const data = prepareObjectForFirebaseUpload({
            Message: alert.message,
            Type: alert.type,
            Active: alert.active,
            MobileFixed: alert.mobileFixed,
            ShowUntil: alert.showUntil,
            ShowStart: alert.showStart ? `${alert.showStart.getHours()}:${alert.showStart.getMinutes()}` : null,
            ShowStop: alert.showStop ? `${alert.showStop.getHours()}:${alert.showStop.getMinutes()}` : null,
            HideDays: alert.hideDays,
            Comment: alert.comment
        })

        if (alert.id == null) {
            return this.db.list(`System/DistrictSettings/${this.adminDistrict}/Alerts`).push(data).then()
        } else {
            return this.db.object(`System/DistrictSettings/${this.adminDistrict}/Alerts/${alert.id}`).update(data)
        }
    }
    public deleteAlert (id: string | number): Promise<void> {
        return this.db.object(`System/DistrictSettings/${this.adminDistrict}/Alerts/${id}`).remove()
    }
}